window.addEventListener('load', () => {
  const currentHeaderNavLink = document.getElementsByClassName('current-header-nav-link')[0]
  if (currentHeaderNavLink) {
    scrollNav(currentHeaderNavLink)
  }
})

function scrollNav(currentHeaderNavLink) {
  const headerNavScroll = document.getElementById("js-header-scroll")

  const currentNavLinkCenter = currentHeaderNavLink.getBoundingClientRect().left + (currentHeaderNavLink.getBoundingClientRect().width / 2)
  const scrollCenter = headerNavScroll.getBoundingClientRect().left + (headerNavScroll.getBoundingClientRect().width / 2)

  headerNavScroll.scrollLeft = headerNavScroll.scrollLeft - (scrollCenter - currentNavLinkCenter)
}
